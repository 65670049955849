import React from "react"
import LazyLoad from "react-lazyload"
import { Modal, Button } from "@lesmills/gatsby-theme-common"
import type { LabelType } from "@lesmills/gatsby-theme-common"
import type {
  ChallengeType,
  ActiveChallengeType,
} from "../../../../types/ChallengesPageType"

type Props = {|
  handleDismiss: () => void | Promise<void>,
  challenge: ChallengeType,
  activeChallenge: ActiveChallengeType,
  data: {
    cancel_button_label: Array<LabelType>,
    switch_challenge_title: Array<LabelType>,
    switch_challenge_message: Array<LabelType>,
    switch_challenge_button_label: Array<LabelType>,
  },
|}

const SwitchChallengeModal = ({
  challenge = {},
  activeChallenge = {},
  data = {},
  handleDismiss = () => {},
}: Props) => {
  const {
    challenge_image = {
      alt: "",
      url: "",
      Mobile: {},
      Tablet: {},
    },
    challenge_name = {},
    challenge_duration = { text: "" },
  } = challenge
  const {
    cancel_button_label = {},
    switch_challenge_title = { text: "" },
    switch_challenge_message = { text: "" },
    switch_challenge_button_label = {},
  } = data

  const getModalTitle = () => {
    // JIRA ticket: https://lesmillsinternational.atlassian.net/browse/ENG-573
    // Without active challenge, we will show accept challenge content
    // With active challenge, we will show switch challenge content
    return switch_challenge_title.text
      .replace("@challenge_name", challenge_name.text)
      .replace("@new_challenge_name", challenge_name.text)
  }

  const getModalDescription = () => {
    return switch_challenge_message.text
      .replace("@challenge_duration", challenge_duration.text.toLowerCase())
      .replace("@old_challenge_name", activeChallenge?.name || "a CHALLENGE")
      .replace("@new_challenge_name", challenge_name.text)
  }

  return (
    <Modal
      handleDismiss={() => handleDismiss(false)}
      classNames={{
        content:
          " challenge-popup w-auto md:w-challenge-popup-md sm:w-challenge-popup sm:h-challenge-popup-sm overflow-auto md:py-0 md:px-0",
      }}
      testId="accept-challenge-modal"
    >
      <div
        className={`challenge-popup-content md:h-full flex justify-between items-center my-0 mx-0`}
      >
        {/* Thumbnail */}
        <LazyLoad height="h-challenge-popup-md">
          <img
            alt={challenge_image.alt}
            src={challenge_image.url}
            className="hidden md:h-full md:block object-cover w-challenge-image"
            data-cy="challenge-image"
            data-testid="challenge-image"
          />
        </LazyLoad>

        {/* Content */}
        <div className="challenge-popup-container flex flex-col justify-center w-full px-24 pt-80 md:pt-0">
          <h5 className="text-6xl md:text-9xl leading-32 uppercase font-primary text-gray-800 uppercase">
            {getModalTitle()}
          </h5>
          <p className="text-gray-800 font-base-light text-base leading-24 my-25">
            {getModalDescription()}
          </p>

          <Button
            handleOnClick={() => handleDismiss(true)} // Dismiss with switching
            disabled={false} // Popup will be dismiss after then so no longer see this button
            submitting={false}
            className={`btn-get-package btn btn-primary-gray pt-3 pb-3 mb-15 mt-25 w-full`}
            testId="switch-challenge-btn"
          >
            {switch_challenge_button_label.text}
          </Button>

          <Button
            handleOnClick={() => handleDismiss(false)} // Dismiss without switching
            disabled={false} // Popup will be dismiss after then so no longer see this button
            submitting={false}
            className={`btn pt-3 pb-3 mb-15 w-full btn-layout`}
            testId="cancel-package-btn"
          >
            {cancel_button_label.text}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default SwitchChallengeModal
